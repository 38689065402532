import React, { useEffect, useRef } from "react"
import PropTypes from "prop-types"

const GradientPlaceholder = ({ width = 600, height = 600 }) => {
  const canvasRef = useRef(null)

  useEffect(() => {
    const canvas = canvasRef.current
    const context = canvas.getContext("2d")

    // Set the canvas size to match the desired dimensions of the image
    canvas.width = width
    canvas.height = height

    // Generate a random gradient
    const gradient = context.createLinearGradient(0, 0, width, height)
    gradient.addColorStop(0, getRandomColor())
    gradient.addColorStop(1, getRandomColor())

    // Fill the canvas with the gradient
    context.fillStyle = gradient
    context.fillRect(0, 0, width, height)
  }, [width, height])

  const getRandomColor = () => {
    const letters = "0123456789ABCDEF"
    let color = "#"
    for (let i = 0; i < 6; i++) color += letters[Math.floor(Math.random() * 16)]

    return color
  }

  return <canvas ref={canvasRef} />
}

GradientPlaceholder.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
}

export default GradientPlaceholder
