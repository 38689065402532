import React from "react"
import Cookies from "js-cookie"
import Balancer from "react-wrap-balancer"
import Layout from "../components/common/layout"
import Seo from "../components/common/seo"
import LevitatePreFooter from "../components/levitate/LevitatePreFooter"
import axios from "axios"
import swagsImg from "../../static/friends/swags.jpeg"
import clsx from "clsx"
import Discord from "../components/common/custom-icons/Discord"
import YouTube from "../components/common/custom-icons/YouTube"
import Note from "../components/common/custom-icons/Note"
import TicketIcon from "../components/common/custom-icons/TicketIcon"
import { Link } from "gatsby"

const FORM_URL = `https://api.hsforms.com/submissions/v3/integration/submit/${process.env.GATSBY_HS_ACCOUNT_ID}/${process.env.GATSBY_HS_SWAG_FORM_ID}`
const tshirtSizes = ["XS", "S", "M", "L", "XL", "XXL", "XXXL"]

const Friends = () => {
  const formRef = React.useRef(null)
  const [loading, setLoading] = React.useState(false)
  const [isSuccess, setIsSuccess] = React.useState(false)
  const [selected, setSelected] = React.useState(false)
  const handleSubmit = async e => {
    e.preventDefault()
    setLoading(true)
    const formData = new FormData(e.currentTarget)
    const data = {
      email: formData.get("email"),
      address: formData.get("address"),
      firstname: formData.get("firstname"),
      lastname: formData.get("lastname"),
      mobilephone: formData.get("mobilephone"),
      // eslint-disable-next-line camelcase
      t_shirt_size: formData.get("t_shirt_size"),
      company: formData.get("company"),
    }

    axios
      .post(FORM_URL, {
        fields: Object.keys(data).map(key => {
          return {
            objectTypeId: "0-1",
            name: key,
            value: data[key],
          }
        }),
        context: {
          hutk: Cookies.get("hubspotutk"),
          pageUri: window && window.location.href,
          pageName: document && document.title,
        },
      })
      .then(() => {
        setLoading(false)
        setIsSuccess(true)
        formRef.current?.reset()
      })
      .catch(() => {
        setLoading(false)
        setIsSuccess(false)
        formRef.current?.reset()
      })
  }

  const handleSelect = () => {
    const formData = new FormData(formRef.current)
    setSelected(formData.get("t_shirt_size") !== "")
  }

  return (
    <Layout>
      {/** Scroll 1 */}
      <div className="mx-auto flex w-full max-w-5xl flex-col items-center bg-white px-4 py-20">
        <div className="mb-20 flex flex-col items-center gap-8">
          <div className="flex flex-col items-center gap-2 md:gap-6">
            <h1 className="text-center text-3xl font-black md:text-6xl">
              Friends of Last9
            </h1>
            <p className="text-center text-base text-slate-600 md:text-2xl">
              <Balancer>
                We love that you rely on us 99.999% of the times.
              </Balancer>
            </p>
          </div>
          <a
            href="#swag-form"
            className="leading-0 flex h-14 w-fit items-center justify-center rounded-full bg-brand-green-200 px-6 text-center text-base font-bold text-white"
          >
            Get Last9 Swag
          </a>
        </div>
        <img
          className="-mb-[40%] aspect-video w-full overflow-hidden rounded-3xl"
          loading="eager"
          src={swagsImg}
          alt="Swag"
        />
      </div>
      {/** Scroll 1 end */}
      {/** Scroll 2 */}
      <div className="w-full border border-solid border-amber-100 bg-yellow-50 py-8 pb-[120px] pt-40 sm:pt-56 md:pt-96 lg:pt-[489px]">
        <div className="mx-auto w-full max-w-5xl px-4">
          {!isSuccess ? (
            <form
              ref={formRef}
              id="swag-form"
              onSubmit={handleSubmit}
              className="solid-light-shadow flex flex-col gap-10 rounded-3xl border border-solid border-slate-100 bg-white p-6 md:p-20"
            >
              <h1 className="text-center text-3xl font-black md:text-6xl">
                Leave your details
              </h1>
              <div className="flex flex-col gap-4">
                <div className="flex w-full flex-col gap-4 md:flex-row md:gap-2">
                  <input
                    className="w-full rounded-full border border-solid border-slate-200 px-4 py-3"
                    placeholder="First name"
                    name="firstname"
                    type="text"
                    disabled={loading}
                    required
                  />
                  <input
                    className="w-full rounded-full border border-solid border-slate-200 px-4 py-3"
                    placeholder="Last name"
                    name="lastname"
                    type="text"
                    disabled={loading}
                    required
                  />
                </div>
                <div className="flex w-full flex-col gap-4 md:flex-row md:gap-2">
                  <input
                    className="w-full rounded-full border border-solid border-slate-200 px-4 py-3"
                    placeholder="Email ID"
                    name="email"
                    type="email"
                    disabled={loading}
                    required
                  />
                  <input
                    className="w-full rounded-full border border-solid border-slate-200 px-4 py-3"
                    placeholder="Mobile with Country Code"
                    name="mobilephone"
                    type="text"
                    disabled={loading}
                    required
                  />
                </div>
                <div className="flex w-full flex-col gap-4 md:flex-row md:gap-2">
                  <input
                    className="w-full rounded-full border border-solid border-slate-200 px-4 py-3"
                    placeholder="Company"
                    name="company"
                    type="text"
                    disabled={loading}
                    required
                  />
                  <select
                    onChange={handleSelect}
                    name="t_shirt_size"
                    className={clsx(
                      "w-full rounded-full border border-solid border-slate-200 px-4 py-3",
                      selected ? "" : "text-slate-500"
                    )}
                    defaultValue=""
                    required
                    disabled={loading}
                  >
                    <option value="" className="hidden text-slate-400" disabled>
                      T-Shirt Size
                    </option>
                    {tshirtSizes.map(size => {
                      return (
                        <option key={size} value={size}>
                          {size}
                        </option>
                      )
                    })}
                  </select>
                </div>
                <textarea
                  className="w-full rounded-3xl border border-solid border-slate-200 px-4 py-3"
                  placeholder="Postal address"
                  name="address"
                  disabled={loading}
                  required
                />
                <button
                  disabled={loading}
                  className="leading-0 flex h-12 w-full items-center justify-center rounded-full bg-brand-green-200 px-6 text-center text-base font-bold text-white"
                >
                  Submit
                </button>
              </div>
            </form>
          ) : (
            <div className="solid-light-shadow rounded-3xl border border-solid border-slate-100 bg-white p-6 text-center text-xl md:p-20">
              Thanks, We will dispatch the T-shirt soon to your place.
            </div>
          )}
        </div>
        <div className="mx-auto mt-20 grid w-full max-w-[584px] grid-cols-1 gap-6 px-6 text-center font-bold leading-6 text-white md:mt-40 md:grid-cols-2">
          <a
            href="https://discord.com/invite/Q3p2EEucx9"
            target="_blank"
            className="flex h-14 items-center justify-start rounded-full bg-[#5865F2] px-6"
            rel="noreferrer"
          >
            <Discord className="h-5 w-5" />
            <span className="flex-1">Join our Discord</span>
          </a>
          <a
            href="https://www.youtube.com/@last9"
            target="_blank"
            className="flex h-14 items-center justify-center rounded-full bg-[#FF0000] px-6"
            rel="noreferrer"
          >
            <YouTube className="h-5 w-5" />
            <span className="flex-1">Subscribe on Youtube</span>
          </a>
          <Link
            to="/blog/subscribe"
            className="flex h-14 items-center justify-center rounded-full bg-brand-blue-100 px-6"
          >
            <Note className="h-5 w-5" />
            <span className="flex-1">Subscribe to our blog</span>
          </Link>
          <Link
            to="/events"
            className="flex h-14 items-center justify-center rounded-full  bg-brand-blue-100 px-6"
          >
            <TicketIcon className="h-5 w-5" />
            <span className="flex-1">Check out our events</span>
          </Link>
        </div>
      </div>
      {/** Scroll 2 end */}
      <LevitatePreFooter />
    </Layout>
  )
}

export const Head = () => (
  <Seo
    title="Friends of Last9"
    description="We love that you rely on us 99.999% of the times."
    image="/thumbnails/thumbnail-last9-friends.jpg"
  />
)

export default Friends
