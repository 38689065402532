import React from "react"
import { Link } from "gatsby"
import dateFormat from "dateformat"
import { PropTypes } from "prop-types"
import GradientPlaceholder from "./GradientPlaceholder"

const Post = ({ post, news = false }) => {
  const slugPage = news ? "news" : "changelog"
  return (
    <Link
      to={`/${slugPage}/${post.slug}/`}
      className="col-span-12 flex snap-proximity snap-start flex-col gap-2 max-md:min-w-[75%] sm:col-span-6 md:col-span-4"
    >
      {post.feature_image ? (
        <img
          src={post.feature_image}
          alt={post.title}
          className="h-48 w-full rounded-2xl border border-slate-100 object-cover"
        />
      ) : (
        <div className="h-48 w-full overflow-hidden rounded-2xl border border-slate-100 object-cover">
          <GradientPlaceholder />
        </div>
      )}

      <span className="font-bold lg:text-2xl lg:font-extrabold">
        {post.title}
      </span>
      <div className="flex flex-col gap-1 opacity-50">
        <p className="text-sm">
          {dateFormat(post.published_at, "mmm dS, ‘yy")}
        </p>
      </div>
    </Link>
  )
}

Post.propTypes = {
  post: PropTypes.object,
  news: PropTypes.string,
}

Post.defaultProps = {
  post: {},
  news: ``,
}

export default Post
