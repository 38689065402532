import React from "react"
import Balancer from "react-wrap-balancer"
import unifiedMonitoringVideo from "../../../static/alert-studio/alert-studio-unified-monitoring.webm"
import oi1 from "../../../static/alert-studio/oi-1.png"
import oi2 from "../../../static/alert-studio/oi-2.png"
import oi3 from "../../../static/alert-studio/oi-3.png"

const UnifiedMonitoring = () => {
  return (
    <div className="mx-auto flex w-full max-w-6xl flex-col items-stretch gap-8 md:flex-row-reverse lg:gap-16">
      {/** Right partition */}
      <div className="flex flex-col gap-6 text-slate-600 md:basis-1/2 md:justify-center">
        <h3 className="w-full text-center text-4xl font-black tracking-tight text-black md:max-w-md md:text-left md:text-6xl">
          <Balancer>Change Intelligence</Balancer>
        </h3>
        <div className="flex flex-col gap-4 text-base md:text-xl">
          <p>
            Introducing <b>Changeboards</b> — A simple view that gives a
            correlated timeline of system health
          </p>
          <p>
            Introducing <b>Change events</b> — captures changes such as
            deployment & configuration changes, notify special calendar events.
          </p>
        </div>
      </div>
      {/** Right partition ends */}
      <div className="flex w-full relative flex-col aspect-[5/3] overflow-hidden rounded-3xl md:w-auto md:basis-1/2">
        <div className="relative w-full h-full flex flex-nowrap alert-studio__carousel">
          <img src={oi1} className="absolute h-full w-full object-cover" />
          <img
            src={oi2}
            className="absolute h-full w-full object-cover translate-x-full"
          />
          <img
            src={oi3}
            className="absolute h-full w-full object-cover translate-x-[200%]"
          />
          <img
            src={oi1}
            className="absolute h-full w-full object-cover translate-x-[300%]"
          />
        </div>
      </div>
    </div>
  )
}

export default UnifiedMonitoring
