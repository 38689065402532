import React from "react"
import Seo from "../components/common/seo"
import Layout from "../components/common/layout"
import Hero from "../components/alert-studio/Hero"
import LevitatePreFooter from "../components/levitate/LevitatePreFooter"
import HealthBoard from "../components/alert-studio/HealthBoard"
import UnifiedMonitoring from "../components/alert-studio/UnifiedMonitoring"
import Accessible from "../components/alert-studio/Accessible"
import Workflows from "../components/alert-studio/Workflows"
import Customizations from "../components/alert-studio/Customizations"
import KillAlertFatigue from "../components/alert-studio/KillAlertFatigue"
import ClientLogosQueue from "../components/common/ClientLogosQueue"

export default function LandingPage() {
  return (
    <Layout>
      {/** Hero section */}
      <section className="flex flex-col px-4 md:px-6 md:pt-14 lg:pt-20 xl:px-0">
        <Hero />
      </section>

      {/** Content section*/}
      <section className="flex flex-col items-center gap-28 bg-gradient-to-b from-yellow-50 to-white px-4 pb-28 md:gap-40 md:px-6 md:pb-40 lg:mx-0 xl:px-0">
        <div className="lg:h-0 w-full">
          <ClientLogosQueue />
        </div>

        <KillAlertFatigue />
        <UnifiedMonitoring />
        <HealthBoard />
        <Accessible />
        {/*<Workflows /> */}
        <Customizations />
      </section>

      <LevitatePreFooter />
    </Layout>
  )
}

export const Head = () => (
  <Seo
    title="Levitate — Alert Studio"
    description="Levitate's Alert Studio is built on the tenets of Actionability, Correlations, and Reliability."
    image="/thumbnails/thumbnail-last9-levitate.jpg"
  />
)
