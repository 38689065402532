import React from "react"
import { Link } from "gatsby"
import { quotes } from "../../../constants/quotes"
import Avatar from "../../../components/common/Avatar"
import akashSaxena from "../../../../static/levitate-tsdb/akash-saxena.jpeg"

const Quote = () => {
  return (
    <div className="mx-auto flex flex-col items-center gap-10 sm:gap-20">
      <div className="relative mx-0 flex w-full max-w-6xl flex-col items-center gap-6 overflow-hidden rounded-3xl bg-brand-blue-100 px-6 py-10 md:gap-10 md:p-10 lg:mx-6 lg:p-20 xl:mx-auto">
        {/* background pattern */}
        <div className="absolute bottom-0 left-0 right-0 top-0">
          {/* prettier-ignore */}
          <svg className="h-full w-full">
            <defs>
              <pattern id="polka-dots-on-dark" x="0" y="0" width="8" height="8" patternUnits="userSpaceOnUse">
                <circle fill="#fff" opacity={0.25} cx="3.5" cy="3.5" r="1" />
              </pattern>
            </defs>
            <rect x="0" y="0" width="100%" height="100%" fill="url(#polka-dots-on-dark)" />
          </svg>
          <div className="absolute bottom-0 left-0 right-0 top-0 h-full w-full bg-gradient-to-tl from-brand-blue-100/75 to-brand-blue-100/95 bg-blend-darken"></div>
        </div>
        {/* end background pattern */}

        <div className="z-10 flex max-w-3xl flex-col items-center justify-center gap-10">
          {/** quote */}
          <div className="flex flex-col items-center gap-5">
            <p className="text-center text-base leading-6 tracking-[-0.33px] text-white md:text-xl md:leading-7">
              {quotes["akash-saxena"]}{" "}
              <Link
                to="/blog/observability-and-sre-at-hotstar-with-last9/"
                className="text-xs hover:underline"
              >
                Read More <sup>↗</sup>
              </Link>
            </p>
            <div className="flex items-center gap-3">
              <Avatar size={40} src={akashSaxena} />
              <div className="flex flex-col">
                <a
                  href="https://www.linkedin.com/in/akisaxena/"
                  target="_blank"
                  rel="noreferrer"
                  className="text-sm text-white hover:underline"
                >
                  Akash Saxena
                </a>
              </div>
            </div>
          </div>
          {/** quote ends */}
        </div>
      </div>
    </div>
  )
}

export default Quote
