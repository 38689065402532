import React, { useState } from "react"
import { Link } from "gatsby"
import { useLocation } from "@reach/router"
import { MenuIcon } from "@heroicons/react/outline"
import PropTypes from "prop-types"
import Navigation from "./navigation"
import MobileNavigation from "./mobile-navigation"

const Header = ({ banner }) => {
  // Show only logo and signup button on campaign landing pages
  const urlsToCheck = ["levitate-whitepaper"]
  const containsUrl = urlToCheck => location.includes(urlToCheck)
  const location = useLocation().pathname
  const [openMenu, setOpenMenu] = useState(false)

  return (
    <header className="fixed top-0 z-50 flex h-[calc(64px+var(--banner-height))] w-full flex-col">
      {banner}
      {urlsToCheck.some(containsUrl) ? (
        <div className="relative border-b border-b-slate-200 bg-white px-4 md:px-6 xl:px-0">
          <div className="mx-auto flex max-w-6xl items-center justify-between gap-8 py-3">
            <Link to="/">
              <span className="sr-only">Last9</span>
              <img
                className="h-10 w-[96.47px]"
                src="/header/logo.svg"
                alt="Last9"
                loading="eager"
              />
            </Link>
            <Link
              to="/schedule-demo/"
              className="rounded-full border-2 border-brand-green-200 bg-brand-green-200 px-4 py-2 text-sm font-semibold text-white transition-colors hover:border-brand-green-200 hover:bg-brand-green-200 hover:text-white focus:outline-none"
            >
              Get Demo
            </Link>
          </div>
        </div>
      ) : (
        <div className="relative h-16 border-b border-b-slate-200 bg-white px-4 md:px-6 xl:px-0">
          <div className="mx-auto flex h-16 max-w-6xl items-center justify-between sm:gap-8">
            {/* logo + mobile menu button */}
            <div className="flex grow items-center gap-2">
              <button
                onClick={() => setOpenMenu(true)}
                className="mt-1 inline-flex items-center justify-center rounded-md bg-white p-2 text-gray-400 hover:bg-gray-100 hover:text-black focus:outline-none focus:ring-2 focus:ring-inset focus:ring-slate-200 lg:hidden"
              >
                <span className="sr-only">Open menu</span>
                <MenuIcon className="h-6 w-6" aria-hidden="true" />
              </button>
              <Link to="/">
                <span className="sr-only">Last9</span>
                <img
                  className="h-10 w-[96.47px]"
                  src="/header/logo.svg"
                  alt="Last9"
                  loading="eager"
                />
              </Link>
            </div>

            <Navigation />

            {/* demo + signup buttons */}
            <div className="flex grow justify-end gap-2">
              <Link
                to="/schedule-demo"
                className="rounded-full border-2 border-brand-green-200 bg-brand-green-200 px-4 py-2 text-sm font-semibold text-white transition-colors focus:outline-none sm:border-brand-green-100 sm:bg-white sm:text-brand-green-200 sm:hover:bg-slate-50"
                target="_blank"
              >
                Book a Demo
              </Link>
              <a
                href="https://app.last9.io/product/levitate"
                className="hidden rounded-full border-2 border-brand-green-200 bg-brand-green-200 px-4 py-2 text-sm font-semibold text-white transition-colors hover:border-brand-green-200 hover:bg-brand-green-200 hover:text-white focus:outline-none sm:block"
              >
                Start for Free
              </a>
            </div>
            {/* end demo + signup buttons */}
          </div>
          {openMenu && <MobileNavigation setOpenMenu={setOpenMenu} />}
        </div>
      )}
    </header>
  )
}

Header.propTypes = { siteTitle: PropTypes.string, banner: PropTypes.node }

Header.defaultProps = { siteTitle: `` }

export default Header
