import React from "react"
import InfoCard from "./InfoCard"
import Click from "../../../static/icons/click.inline.svg"
import Radar from "../../../static/icons/radar.inline.svg"

import magnifyGlass from "../../../static/icons/magnify-glass.webp"
import SectionHead from "./SectionHead"

import AssetDashboard1 from "../../../static/home/asset-dashboard-templates-1.inline.svg"
import AssetDashboard2 from "../../../static/home/asset-dashboard-templates-2.inline.svg"
import AssetAnamoly1 from "../../../static/home/asset-anamoly-change-1.inline.svg"
import AssetAnamoly2 from "../../../static/home/asset-anamoly-change-2.inline.svg"

const Alerting = ({
  title = "Operational and Change Intelligence",
  icon = magnifyGlass,
  infoCards = [
    {
      icon: <Click className="h-6 w-6" />,
      title: "One-click Dashboards and Alerting",
      description:
        "Quickstart your observability and improve coverage of your system with recommendations from our library of standard components for APM and infrastructure monitoring.",
      className: "md:col-span-4 col-span-8",
      align: "top",
      img: (
        <div className="relative">
          <AssetDashboard1 />
          <div className="absolute inset-0">
            <AssetDashboard2 className="opacity-0 transition-opacity duration-500 group-hover:opacity-100" />
          </div>
        </div>
      ),
      cta: (
        <>
          Learn about{" "}
          <a
            href="https://docs.last9.io/docs/alerting-overview"
            title="Alerting for Last9 Levitate"
            target="_blank"
          >
            Alerting
          </a>
          .
        </>
      ),
    },
    {
      icon: <Radar className="h-6 w-6" />,
      title: "Anomaly Detection and Change Events",
      description:
        "Pattern matching algorithms in Alert Studio that go beyond static thresholds to reduce to alert fatigue. Track deployments and feature flags using Change Events.",
      className: "md:col-span-4 col-span-8",
      align: "top",
      img: (
        <div className="relative">
          <AssetAnamoly1 />
          <div className="absolute inset-0">
            <AssetAnamoly2 className="opacity-0 transition-opacity duration-500 group-hover:opacity-100" />
          </div>
        </div>
      ),
      cta: (
        <>
          Learn about{" "}
          <a
            href="https://docs.last9.io/docs/anomalous-pattern-detection-guide"
            title="Anomaly Detection for Last9 Levitate"
            target="_blank"
          >
            Anomaly Detection
          </a>{" "}
          &{" "}
          <a
            href="https://docs.last9.io/docs/change-events"
            title="Change Events for Last9 Levitate"
            target="_blank"
          >
            Change Events
          </a>
          .
        </>
      ),
    },
  ],
}) => {
  return (
    <section className="mx-auto w-full max-w-6xl py-20">
      <div className="flex flex-col gap-16">
        <SectionHead title={title} icon={icon} />
        <div className="grid grid-cols-8 gap-8">
          {infoCards.map(card => (
            <InfoCard key={card.title} {...card} />
          ))}
        </div>
      </div>
    </section>
  )
}

export default Alerting
