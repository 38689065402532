import React from "react"
import { Link } from "gatsby"
import levitateHero from "../../../static/home/levitate.webp"
import ClientLogosQueue from "../common/ClientLogosQueue"

const Hero = () => {
  return (
    <div className="mx-auto block w-full max-w-6xl">
      <div className="grid auto-rows-min grid-cols-1">
        {/* body */}
        <div className="mb-8 flex flex-col-reverse items-center gap-8 md:flex-row">
          <div className="flex flex-1 flex-col gap-8 md:basis-1/2">
            <div className="flex flex-col gap-6 text-base text-slate-600 md:text-xl">
              <h1 className="text-center text-4xl font-black tracking-tight text-black md:text-left md:text-6xl">
                New Relic Alternative
              </h1>
              <p className="text-2xl font-bold leading-8 text-slate-600">
                <strong>Levitate</strong> is Cost Effective Metrics and Events
                based Application Performance Monitoring Platform.
              </p>
              <div className="flex flex-col gap-4 text-base md:text-lg">
                <p>
                  <strong>Globally available</strong> Cloud Native APM solution,
                  built on <strong>Open Standards</strong>. Say Goodbye to
                  vendor-lockin, proprietary query languages and data overages.
                </p>
                <p>
                  Seamlessly oversee multiple applications and micro-services
                  with <strong>auto-instrumentation</strong> libraries, no extra
                  cost for <strong>custom metrics</strong> and{" "}
                  <strong>unlimited data retention</strong>.
                </p>
              </div>
            </div>

            {/* buttons */}
            <div className="flex items-center justify-center gap-4 md:justify-start">
              <a
                href="https://app.last9.io/product/levitate?utm-campaign=monitoring"
                className="leading-0 flex h-14 w-fit items-center justify-center rounded-full bg-brand-green-200 px-6 text-center text-base font-bold text-white"
              >
                Start for Free
              </a>
              <Link
                to="/schedule-demo/?utm-campaign=monitoring"
                className="leading-0 flex h-14 w-fit items-center justify-center rounded-full border-2 border-solid border-brand-green-200 px-6 text-center text-base font-bold text-brand-green-200"
              >
                Book a Demo
              </Link>
            </div>
            {/* end buttons */}
          </div>

          <div className="-mx-4 flex basis-1/2 justify-center bg-yellow-50 md:mx-0 md:bg-transparent">
            <img
              src={levitateHero}
              alt="data policies on levitate"
              className="hidden h-auto max-w-[300px] md:block"
            />
          </div>
        </div>

        {/* end body */}

        <ClientLogosQueue />
      </div>
    </div>
  )
}

export default Hero
