import React from "react"
import Balancer from "react-wrap-balancer"
import { StaticImage } from "gatsby-plugin-image"
import WhiteQuote from "../common/WhiteQuote"
import iacVideo from "../../../static/alert-studio/alert-studio-iac.mp4"

const quote = `Levitate has greatly improved our operations at Hotstar, allowing us to focus on providing live sports and entertainment to our customers. It has eliminated the need for tedious metrics database management tasks and is an essential part of our observability efforts.`

const Accessible = () => {
  return (
    <div className="mx-auto flex w-full max-w-6xl flex-col items-center gap-8 md:flex-row-reverse lg:gap-16">
      {/** left section */}
      <div className="flex flex-col gap-6 text-slate-600 md:basis-1/2 lg:basis-auto">
        <h3 className="max-w-full text-center text-4xl font-black tracking-tight text-black md:max-w-md md:text-left md:text-6xl">
          <Balancer>Agile Config Management</Balancer>
        </h3>
        <div className="flex flex-col gap-4 text-base md:text-xl">
          <p>
            Automated workflows with{" "}
            <a
              className="underline"
              href="https://docs.last9.io/docs/alerting-via-iac"
            >
              Infrastructure as Code (IaC)
            </a>{" "}
            tool for managing alert configurations.
          </p>

          <ol className="marker:text-emerald-500 marker:content-['—'] [&>li]:ml-5 [&>li]:pl-2">
            <li>Component Auto Discovery</li>
            <li>Version History of Configurations</li>
            <li>Peer review of configurations using GitOps</li>
            <li>
              Programmatic Configuration with Python SDK and first class API
            </li>
          </ol>
        </div>
      </div>
      {/** end left section */}

      {/** right section */}
      <div className="flex w-full flex-col overflow-hidden rounded-3xl md:w-auto md:basis-1/2">
        <video width="auto" height="auto" autoPlay loop muted>
          <source src={iacVideo} type="video/mp4" />
        </video>
      </div>
      {/** end right section */}
    </div>
  )
}

export default Accessible
