import React from "react"
import Seo from "../components/common/seo"
import Layout from "../components/common/layout"
import Hero from "../components/levitate/Hero"
import TimeSeriesDBTable from "../components/levitate/TimeSeriesDBTable"
import OpenStandards from "../components/levitate/OpenStandards"
import HighCardinality from "../components/levitate/HighCardinality"
import DataTiering from "../components/levitate/DataTiering"
import MOI from "../components/levitate/MOI"
import LevitatePreFooter from "../components/levitate/LevitatePreFooter"
import Balancer from "react-wrap-balancer"
import AlertStudio from "../components/levitate/AlertStudio"
import ClientLogosQueue from "../components/common/ClientLogosQueue"

export default function LandingPage() {
  return (
    <Layout>
      {/** Hero section */}
      <section className="flex flex-col px-4 pt-14 md:px-6 lg:pt-20 xl:px-0">
        <Hero />
      </section>

      {/** Content section*/}
      <section className="flex flex-col items-center gap-28 bg-gradient-to-b from-yellow-50 to-white px-4 pb-28 md:gap-40 md:px-6 md:pb-40 lg:mx-0 xl:px-0">
        <div className="lg:h-0 w-full">
          <ClientLogosQueue />
        </div>

        <div className="mx-auto flex w-full max-w-6xl -mt-14 lg:mt-0 flex-col items-center gap-8 text-brand-blue-100">
          <h3 className="mx-auto max-w-3xl text-center text-3xl font-black tracking-tight text-black sm:text-6xl">
            <Balancer>A Time Series Data Warehouse</Balancer>
          </h3>
          <p className="max-w-2xl text-center text-base md:text-xl">
            We built Levitate from the ground up, with warehousing capabilities
            baked-in, to mitigate the problems faced by time series databases —
            of high cardinality and concurrent access while providing highly
            available storage, faster queries, and proactive alerting.
          </p>
          <TimeSeriesDBTable />
        </div>

        <HighCardinality />
        <AlertStudio />
        <OpenStandards />

        {/* Divider */}
        <div className="h-0.5 w-full bg-gradient-to-r from-transparent via-brand-green-100 to-transparent opacity-25"></div>

        <MOI />
      </section>

      <LevitatePreFooter />
    </Layout>
  )
}

export const Head = () => (
  <Seo
    title="Levitate - A Time Series Data Warehouse"
    description="A managed time series data warehouse and monitoring solution to tame high cardinality metrics and solve alert fatigue"
    image="/thumbnails/thumbnail-last9-levitate.jpg"
  />
)
