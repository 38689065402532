import React from "react"
import { Link } from "gatsby"
import Balancer from "react-wrap-balancer"

const PreFooter = () => {
  return (
    <section className="flex flex-col items-center justify-center gap-10 bg-yellow-50 px-4 py-14 md:px-6 lg:py-[120px] xl:px-0">
      <h2 className="w-full max-w-3xl text-center text-3xl font-black leading-none text-black md:text-5xl">
        60% cost reduction in your Monitoring Costs
      </h2>
      <p className="max-w-xl text-center text-base text-slate-600 md:text-lg">
        <Balancer>
          Start your monitoring journey today with Levitate — a fully managed,
          cloud monitoring solution.
        </Balancer>
      </p>
      <div className="flex items-center gap-4">
        <Link
          className="leading-0 flex h-14 w-fit items-center justify-center rounded-full bg-brand-green-200 px-6 text-center text-base font-bold text-white"
          to="/schedule-demo/?utm-campaign=monitoring"
        >
          Book a Demo
        </Link>
        <a
          href="https://app.last9.io/product/levitate?utm-campaign=monitoring"
          className="w-fit rounded-full border-2 border-solid border-brand-green-200 px-6 py-3.5 text-center text-base font-bold text-brand-green-200"
        >
          Get Started
        </a>
      </div>
    </section>
  )
}

export default PreFooter
