import React, { useState, useEffect } from "react"
import Layout from "../../components/common/layout"
import Posts from "../../components/changelog/posts"
import axios from "axios"
import Seo from "../../components/common/seo"

const Changelog = () => {
  const [posts, setPosts] = useState(null)

  useEffect(() => {
    // eslint-disable-next-line max-len
    const stringQuery = `&filter=tag:changelog`
    const query = {
      order: "published_at DESC",
      limit: 10,
      include: "tags,authors",
    }

    dataFetch(query, stringQuery).then(data => {
      // Latest posts
      setPosts(data)
    })
  }, [])

  const dataFetch = (query, string = "", tag = "") => {
    tag !== "" && (query.filter = `tag:${tag}`)
    return axios
      .get(
        `${process.env.GATSBY_GHOST_API_URL}/ghost/api/content/posts/?key=${process.env.GATSBY_GHOST_API_KEY}${string}`,
        { params: query }
      )
      .then(res => {
        return res.data.posts
      })
  }

  return (
    <Layout>
      <section className="gradient-blog z-50 pb-10 md:pb-20">
        <div className="space-y-10 md:space-y-20">
          <div className="mx-auto max-w-6xl">
            <Posts
              posts={posts}
              heading="Changelog"
              showFeaturedposts={true}
              clicked={true}
              showSlider={true}
            />
          </div>
        </div>
      </section>
    </Layout>
  )
}

export const Head = () => (
  <Seo
    title="Changelog"
    description="New updates and improvements to Last9"
    image="/thumbnails/thumbnail-last9-changelog.jpg"
  />
)

export default Changelog
