import React from "react"
import Seo from "../components/common/seo"
import Layout from "../components/common/layout"
import Hero from "../components/data-tiering/Hero"
import UnusedMetrics from "../components/data-tiering/UnusedMetrics"
import Policies from "../components/data-tiering/Policies"
import HowTieringWorks from "../components/data-tiering/HowTieringWorks"
import LevitatePreFooter from "../components/levitate/LevitatePreFooter"
import ClientLogosQueue from "../components/common/ClientLogosQueue"

export default function LandingPage() {
  return (
    <Layout>
      {/** Hero section */}
      <section className="flex flex-col px-4 md:px-6 md:pt-14 lg:pt-20 xl:px-0">
        <Hero />
      </section>

      {/** Content section*/}
      <section className="flex flex-col items-center gap-28 bg-gradient-to-b from-yellow-50 to-white px-4 pb-28 md:gap-40 md:px-6 md:pb-40 lg:mx-0 xl:px-0">
        <div className="lg:h-0 w-full">
          <ClientLogosQueue />
        </div>

        <UnusedMetrics />
        <HowTieringWorks />
        <Policies />
      </section>

      <LevitatePreFooter />
    </Layout>
  )
}

export const Head = () => (
  <Seo
    title="Levitate — Data Tiering"
    description="Automatic data tiering based on retention and access policies for long term access to metrics"
    image="/thumbnails/thumbnail-last9-levitate.jpg"
  />
)
