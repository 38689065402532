import React from "react"

const SectionHead = ({ title, icon }) => {
  return (
    <div className="flex flex-col-reverse items-start justify-between gap-5 md:flex-row md:items-center">
      <h2 className="max-w-lg text-4xl font-extrabold text-slate-900 md:text-5xl">
        {title}
      </h2>
      <img alt="" className="h-[120px]" src={icon} />
    </div>
  )
}

export default SectionHead
