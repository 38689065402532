import React from "react"
import {
  BookmarkAltIcon,
  CalendarIcon,
  PlayIcon,
  ShieldCheckIcon,
  SupportIcon,
} from "@heroicons/react/outline"
import Bank from "../../../static/icons/bank.inline.svg"
import ClipboardCode from "../../../static/icons/clipboard-code.inline.svg"
import Folder from "../../../static/icons/folder.inline.svg"
import Insight from "../../../static/icons/insight.inline.svg"
import Radar from "../../../static/icons/radar.inline.svg"
import Input from "../../../static/icons/input.inline.svg"

export const tags = [
  { name: "Levitate", slug: "levitate" },
  { name: "High Cardinality", slug: "high-cardinality" },
  { name: "Last9 Engineering", slug: "last9-engineering" },
  { name: "Deep Dives", slug: "deep-dives" },
]

export const authorSocialLinks = [
  {
    id: 1,
    network: "facebook",
    link: "https://www.facebook.com/",
  },
  {
    id: 2,
    network: "twitter",
    link: "https://twitter.com/",
  },
]

export const navUseCases = [
  {
    name: "Control Plane",
    href: "https://docs.last9.io/docs/control-plane",
    icon: <Bank className="h-6 w-6" />,
    alt: "Tame High Cardinality Metrics with Levitate",
  },
  {
    name: "Instrumentation",
    href: "https://docs.last9.io/docs/integrations",
    icon: <ClipboardCode className="h-6 w-6" />,
    alt: "Tame High Cardinality Metrics with Levitate",
  },
  {
    name: "Ingestion",
    href: "https://docs.last9.io/docs/control-plane-ingestion",
    icon: <Input className="h-6 w-6" />,
    alt: "Alert Studio - Built on the tenets of Actionability, Correlations, and Reliability",
  },
  {
    name: "Storage",
    href: "https://docs.last9.io/docs/control-plane-storage",
    icon: <Folder className="h-6 w-6" />,
    alt: "Reduce monitoring costs with Levitate",
  },
  {
    name: "Query",
    href: "https://docs.last9.io/docs/control-plane-query",
    icon: <Insight className="h-6 w-6" />,
    alt: "Open Standards support in Levitate",
  },
  {
    name: "Alerting",
    href: "https://docs.last9.io/docs/alerting-overview",
    icon: <Radar className="h-6 w-6" />,
    alt: "Open Standards support in Levitate",
  },
]
export const navResources = [
  {
    name: "Customer Stories",
    icon: "/icons/customer-stories-nav.png",
    href: "/customer-stories/",
    alt: "Customer Stories",
  },
  {
    name: "Whitepaper",
    description: "Levitate — Scaling Metrics Maturity in a Cloud-Native World",
    href: "/whitepapers/scaling-metrics-maturity-in-a-cloud-native-world/",
    icon: "/icons/whitepaper-nav.png",
    alt: "Whitepaper",
  },
  {
    name: "Blog",
    description: "Stories on SRE and building Last9",
    href: "/blog/",
    icon: "/icons/blog-nav.png",
    alt: "Blog",
  },
  {
    name: "Events",
    description:
      "Meet Last9 team members worldwide in conferences around Reliability and Observability",
    href: "/events/",
    icon: "/icons/events-nav.png",
    alt: "Events",
  },
  {
    name: "Documentation",
    description:
      "What integrations we support, how to send us data, and other FAQs",
    href: "https://docs.last9.io/",
    target: "_blank",
    icon: "/icons/documentation-nav.png",
    alt: "Documentation",
  },
  {
    name: "Changelog",
    description: "Updates and improvements to Last9",
    href: "/changelog/",
    icon: "/icons/changelog-nav.png",
    alt: "Changelog",
  },
]
export const navigation = [{ name: "Blog", href: "/blog/", icon: PlayIcon }]

export const resources = [
  {
    name: "Help Center",
    description:
      "Get all of your questions answered in our forums or contact support.",
    href: "#",
    icon: SupportIcon,
  },
  {
    name: "Guides",
    description:
      "Learn how to maximize our platform to get the most out of it.",
    href: "#",
    icon: BookmarkAltIcon,
  },
  {
    name: "Events",
    description:
      "See what meet-ups and other events we might be planning near you.",
    href: "#",
    icon: CalendarIcon,
  },
  {
    name: "Security",
    description: "Understand how we take your privacy seriously.",
    href: "#",
    icon: ShieldCheckIcon,
  },
]
