import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import Layout from "../components/common/layout"
import Seo from "../components/common/seo"
import ClientLogo from "../components/common/ClientLogo"

const Schedule = () => {
  React.useLayoutEffect(() => {
    const script = document.createElement("script")
    script.src =
      "https://static.hsappstatic.net/MeetingsEmbed/ex/MeetingsEmbedCode.js"
    document.body.appendChild(script)

    return () => {
      document.body.removeChild(script)
    }
  }, [])

  return (
    <Layout>
      <section className="z-50 mx-auto flex max-w-7xl flex-col items-center gap-10 px-4 pt-14 md:flex-row md:px-6 xl:gap-0 xl:px-0">
        <div className="flex flex-col gap-6 md:max-w-[340px]">
          <h2 className="text-left text-4xl font-black lg:text-6xl">
            Let’s Talk
          </h2>
          <p className="text-slate-600">
            We’d love to understand your monitoring use case and discuss how
            Levitate, our managed time series data warehouse, can help.
          </p>

          <hr className="border-slate-100" />

          {/* proof */}
          <div className="flex flex-col items-start justify-center gap-4 bg-white">
            <div className="z-10 flex h-10 items-center justify-center">
              <div className="flex h-12 items-center justify-center">
                <ClientLogo name="hotstar" className="h-10 w-auto" />
              </div>
            </div>
            <p className="z-10 text-sm text-slate-500">
              “Levitate has eliminated the need for tedious metrics database
              management tasks and is an essential part of our observability
              efforts at Hotstar.”
            </p>
            <div className="z-10 flex items-center gap-3">
              <StaticImage
                src="../../static/home/gaurav-hotstar.jpeg"
                alt="Gaurav Kamboj, Disney+ Hotstar"
                className="h-10 w-10 rounded-full border border-brand-blue-100/10 [&>picture>img]:rounded-full"
              />
              <div className="flex flex-col">
                <span className="text-xs text-slate-500">Gaurav Kamboj</span>
                <span className="text-xs text-brand-green-100">
                  Cloud Architect
                </span>
              </div>
            </div>
          </div>
          {/* end proof */}

          {/* logos */}
          <div className="flex flex-col gap-6 pt-6">
            <div className="flex flex-wrap gap-5 md:gap-4">
              <ClientLogo name="replit" className="h-10 w-auto" />
              <ClientLogo name="clevertap" className="h-10 w-auto" />
              <ClientLogo name="axio" className="h-10 w-auto" />
              <ClientLogo name="probo" className="h-10 w-auto" />
              <ClientLogo name="circle" className="h-10 w-auto" />
              <ClientLogo name="quickwork" className="h-10 w-auto" />
              <ClientLogo name="nxtjob" className="h-10 w-auto" />
              <ClientLogo name="tacitbase" className="h-10 w-auto" />
              <ClientLogo name="dotconn" className="h-10 w-auto" />
            </div>
          </div>
          {/* end logos */}
        </div>

        <div
          className="meetings-iframe-container w-full [&>iframe]:lg:translate-x-10"
          data-src="https://meetings.hubspot.com/prathamesh-sonpatki/product-demos-from-last9io?embed=true"
        ></div>
      </section>
    </Layout>
  )
}

export const Head = () => (
  <>
    <Seo
      title="Schedule Time with Us"
      description="Talk to us on how Levitate can help you manage your monitoring infrastructure and reduce TCO by up-to 50%"
      image="/thumbnails/thumbnail-last9-demo.jpg"
    />
  </>
)

export default Schedule
