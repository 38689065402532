import React from "react"
import { Link } from "gatsby"
import { PropTypes } from "prop-types"

const FeaturedThumbnail = ({ post }) => {
  return (
    <Link
      to={`/blog/${post.slug}/`}
      className="flex basis-1/3 items-center gap-4 md:flex-col md:items-start"
    >
      <img
        src={post.feature_image}
        className="aspect-square w-1/2 rounded-2xl border border-slate-100 object-cover md:w-full"
        loading="lazy"
      />
      <span className="font-bold lg:text-2xl lg:font-extrabold">
        {post.title}
      </span>
    </Link>
  )
}

FeaturedThumbnail.propTypes = { post: PropTypes.object }

FeaturedThumbnail.defaultProps = { post: `` }

export default FeaturedThumbnail
