import React from "react"
import Button from "../common/Button"
import heroImage from "../../../static/home/hero-image.svg"

const Hero = ({
  /**
   * Default title written in jsx. To control from cms pass string
   */
  title = (
    <>
      We build Levitate.{" "}
      <span className="font-medium text-slate-400">
        High cardinality observability at scale.
      </span>
    </>
  ),
  subtitle = "Hassle-free and cost-friendly Cloud Native monitoring with metrics, events, logs, and traces.",
  primaryCta = {
    label: "Start for free",
    href: "https://app.last9.io",
  },
  secondaryCta = {
    label: "View Docs",
    href: "https://docs.last9.io",
  },
}) => {
  return (
    <section className="w-full bg-gradient-to-b from-emerald-100/25 to-emerald-100/0 px-6 pb-12 pt-32">
      <div className="flex w-full max-w-6xl flex-col items-center gap-24 md:mx-auto md:flex-row">
        <div className="relative z-10 flex w-full flex-col gap-6 md:basis-2/3 lg:flex-1">
          <h1 className="max-w-full text-5xl font-extrabold text-slate-900">
            {title}
          </h1>
          <p className="max-w-full text-xl font-medium text-slate-500 md:text-2xl">
            {subtitle}
          </p>
          <div className="flex gap-4">
            <Button
              as="a"
              className="bg-slate-900 text-white"
              href={primaryCta.href}
            >
              {primaryCta.label}
            </Button>
            <Button
              as="a"
              className="bg-slate-100 text-slate-500"
              href={secondaryCta.href}
            >
              {secondaryCta.label}
            </Button>
          </div>
        </div>
        <div className="hidden overflow-visible md:h-[440px] lg:flex lg:flex-1">
          <img
            src={heroImage}
            className="h-[800px] w-[1280px] overflow-visible md:absolute md:-top-10 md:-ml-72"
          />
        </div>
      </div>
    </section>
  )
}

export default Hero
