import "../levitate/tsdb-table.css"

import React from "react"
import Balancer from "react-wrap-balancer"
import {
  Container,
  GreenArrow,
  GreyCard,
  Header,
} from "../levitate/TimeSeriesDBTable"

const problems = [
  {
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="48"
        height="48"
        fill="none"
      >
        <rect width="48" height="48" fill="#EFF6FF" rx="24" />
        <path
          fill="#3B82F6"
          d="M24 33c-.3 0-.587-.054-.863-.163a2.148 2.148 0 0 1-.737-.487l-6.7-6.725a5.462 5.462 0 0 1-1.287-2 6.772 6.772 0 0 1-.413-2.35c0-1.717.558-3.192 1.675-4.425C16.792 15.617 18.183 15 19.85 15c.8 0 1.554.158 2.262.475A6.053 6.053 0 0 1 24 16.8a5.764 5.764 0 0 1 1.863-1.325A5.48 5.48 0 0 1 28.125 15c1.667 0 3.063.617 4.188 1.85 1.124 1.233 1.687 2.7 1.687 4.4 0 .817-.142 1.6-.425 2.35a5.769 5.769 0 0 1-1.275 2l-6.725 6.75A2.18 2.18 0 0 1 24 33Zm1-13c.167 0 .325.042.475.125.15.083.267.192.35.325l1.7 2.55h4.15a4.517 4.517 0 0 0 .35-1.75c-.033-1.15-.417-2.137-1.15-2.962-.733-.826-1.65-1.238-2.75-1.238-.517 0-1.012.1-1.488.3-.474.2-.887.492-1.237.875l-.675.725a.991.991 0 0 1-.725.325.991.991 0 0 1-.4-.087 1.13 1.13 0 0 1-.35-.238l-.675-.725a3.826 3.826 0 0 0-1.225-.9 3.518 3.518 0 0 0-1.5-.325c-1.1 0-2.017.42-2.75 1.262-.733.842-1.1 1.838-1.1 2.988 0 .3.025.596.075.887.05.292.133.58.25.863H21c.167 0 .325.042.475.125.15.083.267.192.35.325l.875 1.3 1.35-4.05c.067-.2.188-.367.363-.5.174-.133.37-.2.587-.2Zm.3 3.25-1.35 4.05c-.067.2-.192.367-.375.5a.999.999 0 0 1-.6.2.964.964 0 0 1-.475-.125.942.942 0 0 1-.35-.325L20.45 25H17.9l5.925 5.925a.246.246 0 0 0 .088.063A.278.278 0 0 0 24 31a.278.278 0 0 0 .087-.012.246.246 0 0 0 .088-.063l5.9-5.925H27a.964.964 0 0 1-.475-.125 1.177 1.177 0 0 1-.375-.325l-.85-1.3Z"
        />
      </svg>
    ),
    title: "Reliable",
    description:
      "Monitoring tools watch your stack. But who watches your monitoring?",
    solution:
      "Alert Studio exposes the runtime metrics of the alert evaluation engine to the user, bringing in accountability and transparency.",
  },
  {
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="48"
        height="48"
        fill="none"
      >
        <rect width="48" height="48" fill="#EFF6FF" rx="24" />
        <path
          fill="#3B82F6"
          d="M28 31c-.45 0-.887-.042-1.313-.125a7.78 7.78 0 0 1-1.237-.35A8.56 8.56 0 0 0 27.7 27.6c.533-1.133.8-2.333.8-3.6a8.352 8.352 0 0 0-.8-3.6 8.56 8.56 0 0 0-2.25-2.925c.4-.15.813-.267 1.238-.35A6.809 6.809 0 0 1 28 17c1.95 0 3.604.68 4.962 2.038C34.322 20.396 35 22.05 35 24c0 1.95-.68 3.604-2.038 4.962C31.604 30.322 29.95 31 28 31Zm-8 0c-1.95 0-3.604-.68-4.963-2.038C13.68 27.604 13 25.95 13 24c0-1.95.68-3.604 2.037-4.962C16.396 17.678 18.05 17 20 17c.45 0 .887.042 1.313.125.425.083.837.2 1.237.35-.283.233-.55.487-.8.762-.25.276-.483.563-.7.863a5.076 5.076 0 0 0-.512-.075A5.657 5.657 0 0 0 20 19c-1.383 0-2.563.488-3.538 1.462C15.488 21.438 15 22.617 15 24s.488 2.563 1.462 3.538C17.438 28.512 18.617 29 20 29c.183 0 .363-.008.538-.025.174-.017.345-.042.512-.075.217.3.45.588.7.863.25.274.517.529.8.762-.4.15-.813.267-1.238.35A6.809 6.809 0 0 1 20 31Zm4-1.55c-.117 0-.233-.02-.35-.063a.797.797 0 0 1-.3-.187 6.178 6.178 0 0 1-1.75-2.325C21.2 25.958 21 25 21 24s.2-1.958.6-2.875c.4-.917.983-1.692 1.75-2.325a.797.797 0 0 1 .3-.188 1.034 1.034 0 0 1 .7 0 .797.797 0 0 1 .3.188 6.178 6.178 0 0 1 1.75 2.325c.4.917.6 1.875.6 2.875s-.2 1.958-.6 2.875a6.178 6.178 0 0 1-1.75 2.325.797.797 0 0 1-.3.188 1.034 1.034 0 0 1-.35.062Z"
        />
      </svg>
    ),
    title: "Correlations",
    description:
      "Not able to get the big picture due to multiple siloed tools?",
    solution:
      "Alert Studio helps identify trends in infra, app, and business metrics. It correlates config & environment changes impacting system health.",
  },
  {
    title: "Actionable",
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="48"
        height="48"
        fill="none"
      >
        <rect width="48" height="48" fill="#EFF6FF" rx="24" />
        <path
          fill="#3B82F6"
          d="M17 31a.968.968 0 0 1-.712-.288A.968.968 0 0 1 16 30c0-.283.096-.52.288-.712A.968.968 0 0 1 17 29h1v-7c0-1.383.417-2.613 1.25-3.688.833-1.075 1.917-1.779 3.25-2.112v-.7c0-.417.146-.77.438-1.063A1.446 1.446 0 0 1 24 14c.417 0 .77.146 1.063.438.291.291.437.645.437 1.062v.7c1.333.333 2.417 1.038 3.25 2.113C29.583 19.387 30 20.616 30 22v7h1c.283 0 .52.096.712.288.192.191.288.429.288.712s-.096.52-.288.712A.968.968 0 0 1 31 31H17Zm7 3c-.55 0-1.02-.196-1.413-.587A1.926 1.926 0 0 1 22 32h4c0 .55-.196 1.02-.587 1.413A1.926 1.926 0 0 1 24 34Zm-4-5h8v-7c0-1.1-.392-2.042-1.175-2.825C26.042 18.392 25.1 18 24 18s-2.042.392-2.825 1.175C20.392 19.958 20 20.9 20 22v7Z"
        />
      </svg>
    ),
    description:
      "Are false, noisy, and stale alerts destroying the effectiveness of alerting?",
    solution:
      "IaC and GitOps workflows streamline alert setups, while Anomalous Pattern Detection tackles false positives arising from static thresholds.",
  },
]

const steps = [
  {
    title: "Templates",
    items: [
      "Standard Alert Rules",
      "Pre-built Dashboards",
      "Automatic Service Discovery",
      "Customization with IaC",
    ],
  },
  {
    title: "Configuration",
    items: [
      "Native PromQL support",
      "Migrate from AlertManager & Grafana",
      "Gitops workflows",
    ],
  },
  {
    title: "Intelligence",
    items: [
      "Real Time Pattern Change Detection",
      "Change Events",
      "Historical trends",
      "Changeboards for correlations",
    ],
  },
  {
    title: "Integrations",
    items: [
      "Slack notifications",
      "Pagerduty & Opsgenie integrations",
      "Dedup & Repeat notifications",
      "Post incident workflows",
    ],
  },
  {
    title: "Reporting",
    items: [
      "Alert efficacy reports",
      "MTBI analysis reports",
      "Adaptive SLOs",
      "Threshold analysis",
      "Run length analysis",
      "Alert health metrics",
    ],
  },
]

const KillAlertFatigue = () => {
  return (
    <div className="mx-auto flex w-full max-w-5xl -mt-14 lg:mt-0 flex-col items-center gap-8">
      <h3 className="text-center text-4xl font-black tracking-tight text-black md:text-6xl">
        <Balancer>Core Tenets of Alert Studio</Balancer>
      </h3>
      <div className="grid grid-cols-1 gap-4 md:grid-cols-3">
        {problems.map(problem => {
          return (
            <div
              key={problem.title}
              className="grid-rows-subgrid row-span-4 grid gap-2 rounded-lg border border-solid border-slate-100 bg-white p-6"
            >
              {problem.icon}

              <p className="mt-2 text-base font-bold text-slate-600">
                {problem.title}
              </p>
              <span className="flex gap-2">
                <span className="flex inline-flex flex-col items-center justify-start gap-1.5">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    fill="none"
                  >
                    <path
                      fill="#F87171"
                      d="M3.725 17.081h12.55c1.283 0 2.083-1.391 1.442-2.5L11.442 3.74a1.666 1.666 0 0 0-2.884 0L2.283 14.58c-.641 1.109.159 2.5 1.442 2.5ZM10 11.248a.836.836 0 0 1-.833-.833V8.748c0-.458.375-.833.833-.833.458 0 .833.375.833.833v1.667a.836.836 0 0 1-.833.833Zm.833 3.333H9.167v-1.666h1.666v1.666Z"
                    />
                  </svg>
                  <div className="w-px flex-1 rounded-full bg-slate-200" />
                </span>
                <p className="flex-1 text-sm text-slate-500">
                  {problem.description}
                </p>
              </span>
              <span className="flex gap-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  fill="none"
                >
                  <path
                    fill="#10B981"
                    d="M10 1.667A8.336 8.336 0 0 0 1.667 10c0 4.6 3.733 8.333 8.333 8.333S18.333 14.6 18.333 10 14.6 1.667 10 1.667ZM7.742 13.575 4.75 10.583a.83.83 0 1 1 1.175-1.175l2.408 2.4 5.734-5.733a.83.83 0 1 1 1.175 1.175l-6.325 6.325a.83.83 0 0 1-1.175 0Z"
                  />
                </svg>
                <p className="flex-1 text-sm text-slate-500">
                  {problem.solution}
                </p>
              </span>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default KillAlertFatigue
