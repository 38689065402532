import React from "react"
import customizations from "../../../static/alert-studio/customizations.png"

const Customizations = () => {
  return (
    <div className="mx-auto flex w-full max-w-6xl flex-col items-stretch gap-8 md:flex-row lg:gap-16">
      {/** Left partition */}
      <div className="flex flex-col gap-6 text-slate-600 md:basis-1/2 md:justify-center">
        <h3 className="w-full text-center text-4xl font-black tracking-tight text-black md:max-w-md md:text-left md:text-6xl">
          Open Standards
        </h3>
        <div className="flex flex-col gap-4 text-base md:text-xl">
          <p>
            Alert Studio is <b>fully PromQL-compatible</b>. One-click migration
            from Prometheus and Grafana alerts.
          </p>

          <p>
            Know more about getting started with{" "}
            <a
              href="https://docs.last9.io/docs/alerting-overview"
              target="_blank"
              rel="noreferrer"
              className="underline"
            >
              Alert Studio
            </a>
            .
          </p>
        </div>
      </div>
      {/** Left partition ends */}

      <div className="max-h-[436px] w-full overflow-hidden rounded-3xl md:w-auto md:basis-1/2">
        <img src={customizations} className="max-h-[436px] w-auto" />
      </div>
    </div>
  )
}

export default Customizations
