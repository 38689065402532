import React from "react"
import { Link } from "gatsby"
import Balancer from "react-wrap-balancer"

const LevitatePreFooter = () => {
  return (
    <section className="flex flex-col items-center justify-center gap-10 bg-yellow-50 px-4 py-14 md:px-6 lg:py-[120px] xl:px-0">
      <p className="w-full max-w-4xl text-center text-3xl font-black leading-none text-black md:text-5xl">
        <Balancer>
          Do away with the toil of managing your own Prometheus
        </Balancer>
      </p>
      <p className="max-w-xl text-center text-base text-slate-600 md:text-xl">
        <Balancer>
          Start your monitoring journey today with Levitate. A Managed Time
          Series Data Warehouse that SREs trust.
        </Balancer>
      </p>
      <div className="flex items-center gap-4">
        <a
          href="https://app.last9.io/product/levitate"
          className="leading-0 flex h-14 w-fit items-center justify-center rounded-full bg-brand-green-200 px-6 text-center text-base font-bold text-white"
        >
          Start for Free
        </a>
        <Link
          to="/schedule-demo"
          className="w-fit rounded-full border-2 border-solid border-brand-green-200 px-6 py-3.5 text-center text-base font-bold text-brand-green-200"
        >
          Book a Demo
        </Link>
      </div>
    </section>
  )
}

export default LevitatePreFooter
