/* eslint-disable react/prop-types */
import React from "react"
import { Link } from "gatsby"
import { ChevronRightIcon } from "@heroicons/react/solid"

const UseCases = ({ cases }) => {
  return (
    <div className="relative grid grid-flow-row grid-cols-1 gap-x-6 rounded-lg bg-white p-2 shadow-lg ring-1 ring-black ring-opacity-5 lg:w-[512px] lg:grid-cols-2 lg:bg-transparent lg:p-3 lg:pt-4 lg:shadow-none lg:ring-0">
      {cases.map(item => (
        <Link
          key={item.name}
          to={item.href}
          target={item.target ? item.target : ""}
          className="active-hover group flex w-full items-center justify-between gap-4 rounded-lg px-4 py-2 lg:p-3"
        >
          {item.icon}
          <div className="flex grow flex-col gap-1">
            <p className="font-medium text-slate-600 lg:text-sm">{item.name}</p>
          </div>
          <div className="flex items-center">
            <ChevronRightIcon className="h-5 fill-slate-400" />
          </div>
        </Link>
      ))}
    </div>
  )
}

export default UseCases
