import React from "react"
import { Link } from "gatsby"
import openStandards from "../../../static/icons/open-standards-hero.png"
import openStandardsHero from "../../../static/microsites/open-standards-hero.png"

const Hero = () => {
  return (
    <div className="mx-auto block w-full max-w-6xl">
      {/* icon */}
      <div className="mb-8 hidden h-16 w-12 items-center justify-center rounded-full md:flex">
        <img className="h-full w-full" src={openStandards} />
      </div>
      {/* end icon */}

      {/* body */}
      <div className="mb-40 flex flex-col-reverse items-center gap-8 md:flex-row">
        <div className="flex flex-col gap-8 md:basis-1/2">
          <div className="flex flex-col gap-6 text-slate-600">
            <h2 className="text-center text-4xl font-black tracking-tight text-black md:text-left md:text-6xl">
              Open Standards
            </h2>
            <div className="flex flex-col gap-4 text-base md:text-xl">
              <p>
                Like you, we hate being vendor-locked into closed platforms.
              </p>
              <p>
                That’s why Levitate supports open standards — OpenMetrics,
                OpenTelemetry, and integrates with open-source tools such as
                Prometheus, VictoriaMetrics, InfluxDB, Telegraf, and StatsD.
              </p>
            </div>
          </div>

          {/* buttons */}
          <div className="flex items-center justify-center gap-4 md:justify-start">
            <a
              href="https://app.last9.io/product/levitate"
              className="leading-0 flex h-14 w-fit items-center justify-center rounded-full bg-brand-green-200 px-6 text-center text-base font-bold text-white"
            >
              Start for Free
            </a>
            <Link
              to="/schedule-demo"
              className="leading-0 flex h-14 w-fit items-center justify-center rounded-full border-2 border-solid border-brand-green-200 px-6 text-center text-base font-bold text-brand-green-200"
            >
              Book a Demo
            </Link>
          </div>
          {/* end buttons */}
        </div>

        <div className="-mx-4 basis-1/2 bg-yellow-50 md:mx-0 md:bg-transparent">
          <img
            src={openStandardsHero}
            alt="data policies on levitate"
            className="max-h-full"
          />
        </div>
      </div>
      {/* end body */}
    </div>
  )
}

export default Hero
