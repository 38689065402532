import React from "react"
import Quote from "./Quote"
import InfoCard from "./InfoCard"
import SectionHead from "./SectionHead"
import Bank from "../../../static/icons/bank.inline.svg"
import ClipboardCode from "../../../static/icons/clipboard-code.inline.svg"
import Folder from "../../../static/icons/folder.inline.svg"
import Insight from "../../../static/icons/insight.inline.svg"

import ControlPanel1 from "../../../static/home/control-panel-1.inline.svg"
import ControlPanel2 from "../../../static/home/control-panel-2.inline.svg"
import AssetIntegrations1 from "../../../static/home/asset-integrations-1.inline.svg"
import AssetIntegrations2 from "../../../static/home/asset-integrations-2.inline.svg"
import AssetStorage1 from "../../../static/home/asset-storage-1.inline.svg"
import AssetStorage2 from "../../../static/home/asset-storage-2.inline.svg"
import AssetQuery1 from "../../../static/home/asset-query-1.inline.svg"
import AssetQuery2 from "../../../static/home/asset-query-2.inline.svg"

import warehouse from "../../../static/icons/warehouse.webp"
import imageSrc from "../../../static/home/hotstar-quote-image.webp"

const Warehouse = ({
  title = "Telemetry Warehouse, not just a database.",
  icon = warehouse,
  infoCards = [
    {
      icon: <Bank className="h-6 w-6" />,
      iconColor: "text-emerald-500",
      title: "Control Plane as a first-class citizen.",
      className: "col-span-8",
      description:
        "With a developer experience for your data, its configurations, and its lifecycle — manage access, understand usage and quotas, define routing paths and more.",
      cta: (
        <>
          Learn about{" "}
          <a
            href="https://docs.last9.io/docs/control-plane"
            title="Control Plane for Last9 Levitate"
            target="_blank"
          >
            Control Plane
          </a>
          .
        </>
      ),
      align: "left",
      img: (
        <div className="relative">
          <ControlPanel1 />
          <div className="absolute inset-0">
            <ControlPanel2 className="opacity-0 transition-opacity duration-500 group-hover:opacity-100" />
          </div>
        </div>
      ),
    },
    {
      icon: <ClipboardCode className="h-6 w-6" />,
      iconColor: "text-blue-500",
      title: "The work starts on the client side, pre-ingestion.",
      description:
        "With support for 50+ data sources, automate coverage for standard components, manipulate incoming data in real-time to generate scoped metrics with optimized cardinality, scan for sensitive data during ingestion, and more.",
      className: "col-span-8 md:col-span-4",
      cta: (
        <>
          Learn about{" "}
          <a
            href="https://docs.last9.io/docs/integrations"
            title="Instrumentation for Last9 Levitate"
            target="_blank"
          >
            Instrumentation
          </a>{" "}
          &{" "}
          <a
            href="https://docs.last9.io/docs/control-plane-ingestion"
            title="Ingestion control plane for Last9 Levitate"
            target="_blank"
          >
            Ingestion
          </a>
          .
        </>
      ),
      align: "top",
      img: (
        <div className="relative">
          <AssetIntegrations1 />
          <div className="absolute inset-0">
            <AssetIntegrations2 className="opacity-0 transition-opacity duration-500 group-hover:opacity-100" />
          </div>
        </div>
      ),
    },
    {
      icon: <Folder className="h-6 w-6" />,
      iconColor: "text-yellow-500",
      title: "No sampling. Pocket-friendly retention policies.",
      description:
        "Modern observability needs require both granular data and long-term historical data. Engineering teams shouldn’t be worried about cost-optimizations, they should be focused on serving the needs of your customers.",
      className: "col-span-8 md:col-span-4",
      align: "top",
      cta: (
        <>
          Learn about{" "}
          <a
            href="https://docs.last9.io/docs/control-plane-storage"
            title="Storage control plane for Last9 Levitate"
            target="_blank"
          >
            Storage
          </a>
          .
        </>
      ),
      img: (
        <div className="relative">
          <AssetStorage1 />
          <div className="absolute inset-0">
            <AssetStorage2 className="opacity-0 transition-opacity duration-500 group-hover:opacity-100" />
          </div>
        </div>
      ),
    },
    {
      icon: <Insight className="h-6 w-6" />,
      iconColor: "text-red-500",
      title:
        "Use high cardinality & dimensionality data without crippling your monitoring.",
      description:
        "No more sluggish dashboards and unreliable alerts. With accelerated queries, optimized cardinality workflows, and purpose-built alerting, high-cardinality & high-dimensionality become an asset, not a liability.",
      className: "col-span-8",
      align: "right",
      cta: (
        <>
          Learn about{" "}
          <a
            href="https://docs.last9.io/docs/control-plane-query"
            title="Query control plane for Last9 Levitate"
            target="_blank"
          >
            Query
          </a>{" "}
          &{" "}
          <a
            href="https://docs.last9.io/docs/alerting-overview"
            title="Alerting for Last9 Levitate"
            target="_blank"
          >
            Alerting
          </a>
          .
        </>
      ),
      img: (
        <div className="relative">
          <AssetQuery1 />
          <div className="absolute inset-0">
            <AssetQuery2 className="opacity-0 transition-opacity duration-500 group-hover:opacity-100" />
          </div>
        </div>
      ),
    },
  ],
  quote = {
    client: "hotstarcolor",
    quote:
      "Last9 has been an amazing partner in making inroads on what a solid observability platform should be.",
    author: "— Akash Saxena, ex-CTO, Disney+ Hotstar",
    accent: "#1C00FF",
    img: <img src={imageSrc} className="h-auto w-full max-w-80" />,
  },
}) => {
  return (
    <section className="bg-gradient-to-b from-yellow-50 to-yellow-50/0 px-6 py-20">
      <div className="mx-auto flex w-full max-w-6xl flex-col gap-16">
        <SectionHead title={title} icon={icon} />
        <div className="grid w-full grid-cols-8 gap-4">
          {infoCards.map((card, index) => (
            <InfoCard key={index} {...card} />
          ))}
        </div>
        <Quote {...quote} />
      </div>
      <div></div>
    </section>
  )
}

export default Warehouse
