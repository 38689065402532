import React from "react"
import clsx from "clsx"
import PropTypes from "prop-types"
import levitateLogo from "../../../static/home/home-levitate-icon.svg"

const integrations = [
  {
    src: levitateLogo,
    bg: "#dcfce7",
    name: "Levitate",
    href: "/levitate-tsdb",
    absoluteHref: true,
    invert: false,
  },
  {
    src: "https://cdn.simpleicons.org/prometheus/fff",
    bg: "#E6522C",
    name: "Prometheus",
    href: "/levitate-integrations-prometheus",
  },
  {
    src: "https://cdn.simpleicons.org/opentelemetry/fff",
    bg: "#425CC7",
    name: "OpenTelemetry",
    href: "/levitate-integrations-opentelemetry",
  },
  {
    href: "/blog/thanos-vs-cortex",
    src: "https://last9.github.io/assets-docs/integration-cortex.svg",
    name: "Cortex",
    bg: "#3B697E",
    invert: false,
    absoluteHref: true,
  },
  {
    src: "https://cdn.simpleicons.org/victoriametrics/fff",
    bg: "#E94600",
    name: "vmagnet",
    href: "/levitate-integrations-vmagent",
  },
  {
    src: "https://cdn.simpleicons.org/influxdb/fff",
    bg: "#22ADF6",
    name: "Telegraf",
    href: "/levitate-integrations-telegraf",
  },
  {
    src: "https://last9.github.io/assets-docs/integration-statsd.png",
    invert: false,
    bg: "#3DB98F",
    name: "statsd",
    href: "/levitate-integrations-statsd",
  },
  {
    src: "https://cdn.simpleicons.org/amazonwebservices/fff",
    name: "AWS PrivateLink",
    bg: "#232F3E",
    href: "/levitate-integrations-aws-cloudstream",
  },
  {
    src: "https://last9.github.io/assets-docs/integration-thanos.png",
    name: "Thanos",
    bg: "#6D41FF",
    invert: false,
    absoluteHref: true,
    href: "/blog/thanos-vs-cortex",
  },
]

const Integrations = ({ skipIntegrations = [] }) => {
  return (
    <div className="grid w-full grid-cols-3 gap-4 text-white">
      {integrations
        .filter(({ name }) => !skipIntegrations.includes(name))
        .map(integration => {
          return (
            <a
              key={integration.name}
              href={
                integration?.absoluteHref
                  ? integration.href
                  : `https://docs.last9.io/docs${integration.href}`
              }
              target="_blank"
              style={{ backgroundColor: integration?.bg }}
              className={clsx(
                "flex aspect-square flex-col items-center justify-center gap-6 rounded-2xl bg-white p-4 text-center md:p-8",
                integration?.class,
              )}
              rel="noreferrer"
            >
              <img
                src={integration.src}
                alt={integration.name}
                className={clsx(
                  "h-12 w-12",
                  integration?.invert ?? false ? "invert" : "",
                )}
              />
            </a>
          )
        })}
    </div>
  )
}

Integrations.propTypes = {
  skipIntegrations: PropTypes.arrayOf(PropTypes.string),
}

export default Integrations
