/* eslint-disable react/prop-types */
import React from "react"
import ClientLogo from "./ClientLogo"

const WhiteQuote = ({ quote, logo, author }) => {
  return (
    <div className="bg-brand-gradient w-full rounded-3xl p-0.5 md:basis-1/2 lg:w-[488px] lg:min-w-[488px] lg:basis-auto">
      <div className="relative flex flex-col items-start justify-center gap-5 overflow-hidden rounded-[22px] bg-white p-6 text-sm text-white md:text-base lg:p-10 xl:h-96">
        {/* background pattern */}
        <div className="absolute bottom-0 left-0 right-0 top-0 z-0">
          {/* prettier-ignore */}
          <svg className="h-full w-full pointer-events-none">
            <defs>
              <pattern id="polka-dots-on-light" x="0" y="0" width="8" height="8" patternUnits="userSpaceOnUse">
                <circle fill="#273747" opacity={0.25} cx="3.5" cy="3.5" r="1" />
              </pattern>
            </defs>
            <rect x="0" y="0" width="100%" height="100%" fill="url(#polka-dots-on-light)" />
          </svg>
          <div className="absolute bottom-0 left-0 right-0 top-0 h-full w-full bg-gradient-to-tl from-white/75 to-white/95 bg-blend-lighten"></div>
        </div>
        {/* end background pattern */}

        {/** logo */}
        <ClientLogo name={logo} size={64} color="#94A3B8" className="z-10" />
        {/** logo end */}

        <p className="z-10 text-slate-500">“{quote}”</p>
        <div className="z-10 flex gap-3">
          {author?.image}
          <div className="flex flex-col">
            <span className="text-sm text-slate-500">{author?.name}</span>
            <span className="text-sm text-brand-green-200">
              {author?.caption}
            </span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default WhiteQuote
