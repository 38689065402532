import React from "react"
import levitateIcon from "../../../static/home/home-levitate-icon.svg"
import PropTypes from "prop-types"
import clsx from "clsx"
import "./tsdb-table.css"
import { integrations } from "../../constants/integrations"

export const Header = ({ title }) => {
  return (
    <div className="relative flex h-fit shrink-0 flex-col items-center gap-2 self-stretch rounded-md bg-slate-700 py-3 md:h-20">
      <div className="left-5 top-6 z-10 flex items-center justify-start gap-3 md:absolute">
        <img className="relative h-8 w-8" src={levitateIcon} alt="Levitate" />
        <div className="text-left text-sm font-bold uppercase text-white">
          Levitate
        </div>
      </div>
      <div className="left-0 top-0 flex h-fit w-full items-center justify-center rounded-md bg-slate-700 md:absolute md:h-20">
        <span className="text-center text-base font-medium text-white">
          {title}
        </span>
      </div>
    </div>
  )
}

Header.propTypes = {
  title: PropTypes.string.isRequired,
}

export const GreenArrow = ({ text, className }) => {
  return (
    <div
      className={clsx(
        "vertical-rl md:horizontal-tb relative w-fit rounded-md bg-emerald-300 px-2.5 py-4 text-center font-sans text-sm font-bold uppercase text-slate-600 md:w-full",
        className,
      )}
    >
      <span className="block rotate-180 md:rotate-0">{text}</span>
    </div>
  )
}

GreenArrow.propTypes = {
  text: PropTypes.string.isRequired,
  className: PropTypes.string,
}

export const GreyCard = ({ heading, items }) => {
  return (
    <div className="flex h-fit min-h-[220px] w-full flex-1 flex-col gap-4 rounded-md bg-slate-100 p-4">
      {typeof heading === "string" ? (
        <>
          <span className="text-center font-bold text-slate-600 md:text-left">
            {heading}
          </span>
          <div className="h-0.5 w-full rounded-sm bg-slate-200"></div>
        </>
      ) : null}
      <ul className="flex flex-col gap-3 text-center md:text-left">
        {items?.map((item, index) => {
          return (
            <li key={item + index} className="text-base font-medium leading-6">
              {item}
            </li>
          )
        })}
      </ul>
    </div>
  )
}

GreyCard.propTypes = {
  heading: PropTypes.string.isRequired,
  items: PropTypes.array.isRequired,
}

export const Container = ({ children }) => {
  return (
    <div className="flex w-full max-w-full flex-row gap-2 md:flex-col">
      {children}
    </div>
  )
}

const TimeSeriesDBTable = () => {
  return (
    <div className="relative flex h-full w-full flex-col items-start justify-start gap-5 rounded-xl border-2 border-solid border-slate-100 bg-[#ffffff] p-5">
      {/* header */}
      <Header title="Time Series Data Warehouse" />
      {/* end header */}

      {/* body */}
      <div className="md:wrap-grid flex w-full flex-col gap-4">
        <Container>
          <GreenArrow
            text="Instrumentation"
            className="green-arrow-head md:green-arrow-head-right"
          />
          <div className="flex h-full w-full items-center justify-center rounded-md bg-slate-100 py-2.5">
            <div className="grid h-fit w-fit grid-cols-2 grid-rows-3 gap-2.5">
              {integrations.slice(0, 6).map((integration, index) => {
                return (
                  <div
                    key={integration.src + index}
                    className={clsx(
                      "flex h-14 w-14 items-center justify-center rounded-xl",
                    )}
                    style={{ backgroundColor: integration.bg }}
                  >
                    <img
                      key={integration.src + index}
                      src={integration.src}
                      alt={integration.name}
                      className={clsx("cols h-8 w-8", {
                        invert: integration?.invert ?? false,
                      })}
                    />
                  </div>
                )
              })}
            </div>
          </div>
        </Container>
        <Container>
          <GreenArrow
            text="Ingestion"
            className="green-arrow-head md:green-arrow-head-right"
          />
          <GreyCard
            heading="Flow Control"
            items={[
              "Cardinality Limiter",
              "Concurrency Limiter",
              "Streaming Aggregation",
            ]}
          />
        </Container>
        <Container>
          <GreenArrow
            text="Storage"
            className="green-arrow-head md:green-arrow-head-right"
          />
          <GreyCard
            heading="Data Tiering"
            items={["Blaze Tier", "Cold Tier", "No Downsampling"]}
          />
        </Container>
        <Container>
          <GreenArrow
            text="Query"
            className="green-arrow-head md:green-arrow-head-right"
          />
          <GreyCard
            heading="Access Control"
            items={[
              "Access Tokens & Policy",
              "Client Separation",
              "Query Acceleration",
            ]}
          />
        </Container>
        <Container>
          <GreenArrow text="Alerting" />
          <GreyCard
            heading="Ease of Use"
            items={[
              "Familiarity of PromQL",
              "Democratization of alert rules",
              "Cardinality Limiter",
            ]}
          />
        </Container>
      </div>
      {/* end body */}
    </div>
  )
}

export default TimeSeriesDBTable
