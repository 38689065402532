import React from "react"
import workflows from "../../../static/alert-studio/workflows.png"

const Workflows = () => {
  return (
    <div className="mx-auto flex w-full max-w-6xl flex-col items-stretch gap-8 md:flex-row-reverse lg:gap-16">
      <div className="flex w-full flex-col overflow-hidden rounded-3xl md:w-auto md:basis-1/2">
        <img src={workflows} className="h-auto w-auto" />
      </div>

      {/** Right partition */}
      <div className="flex flex-col gap-6 text-slate-600 md:basis-1/2 md:justify-center">
        <h3 className="w-full text-center text-4xl font-black tracking-tight text-black md:max-w-md md:text-left md:text-6xl">
          Deep integrations with incident management tools
        </h3>
        <div className="flex flex-col gap-4 text-base md:text-xl">
          <p>
            Not all alerts are important. Use rich alert metadata from Alert
            Studio notifications to create automated incident workflows in
            Slack, Pagerduty and Opsgenie.{" "}
          </p>
          <blockquote className="border-l-2 border-solid border-slate-400 py-2 pl-4 text-base text-slate-500">
            When an SLO drop is greater than 17% —&gt; Create a JIRA ticket.
            However, if it’s only 5% then assign it to an on-call team. But
            again, if it’s only 1%, then ignore it.
          </blockquote>
        </div>
      </div>
      {/** Right partition ends */}
    </div>
  )
}

export default Workflows
