import React from "react"
import { Link } from "gatsby"

const Hero = () => {
  return (
    <div className="w-full bg-yellow-50 px-4 pb-60 pt-20">
      <div className="mx-auto flex w-full max-w-6xl flex-col items-center gap-8">
        <div className="mx-auto flex w-full max-w-xl flex-col items-center gap-6 text-center">
          <h1 className="text-4xl font-black leading-[1.133] md:text-6xl">
            Customers trust Last9
          </h1>
          <p className="text-base leading-6 text-slate-600 md:text-2xl md:leading-8">
            Learn how some of the best engineering teams in the world are
            achieving their Observability goals with Last9.
          </p>
        </div>
        <Link
          className="w-fit rounded-full bg-emerald-500 px-6 py-4 text-center text-base font-bold leading-6 text-white"
          to="/schedule-demo"
        >
          Schedule a Demo
        </Link>
        {/*
        <div className="flex w-full flex-col items-center justify-center gap-12 pt-10 md:flex-row md:gap-24">
          <div className="flex w-full max-w-[192px] flex-col items-center text-emerald-600">
            <p className="text-[32px] font-bold leading-10">40%</p>
            <p className="text-center text-sm font-bold uppercase leading-5">
              reduced irrelevant alerts
            </p>
          </div>
          <div className="flex w-full max-w-[192px] flex-col items-center text-emerald-600">
            <p className="text-[32px] font-bold leading-10">40%</p>
            <p className="text-center text-sm font-bold uppercase leading-5">
              reduced irrelevant alerts
            </p>
          </div>
          <div className="flex w-full max-w-[192px] flex-col items-center text-emerald-600">
            <p className="text-[32px] font-bold leading-10">40%</p>
            <p className="text-center text-sm font-bold uppercase leading-5">
              reduced irrelevant alerts
            </p>
          </div>
          </div>
          */}
      </div>
    </div>
  )
}

export default Hero
