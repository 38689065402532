/* eslint-disable react/prop-types */
import React from "react"
import { Link } from "gatsby"
import { ChevronDownIcon } from "@heroicons/react/solid"
import { navUseCases, navResources } from "./data"
import Resources from "./navigation/resources"
import UseCases from "./navigation/usecases"

const navHeaders = [
  {
    id: "use-cases",
    title: "Platform",
    type: "popover",
    element: <UseCases cases={navUseCases} />,
  },
  {
    id: "resources",
    title: "Resources",
    type: "popover",
    element: <Resources resources={navResources} />,
  },
  {
    id: "blog",
    title: "Blog",
    type: "href",
    href: "/blog/",
  },
  {
    id: "about-us",
    title: "About Us",
    type: "href",
    href: "/about-us/",
  },
]

const Navigation = () => {
  return (
    <div className="navigation">
      <nav className="relative hidden lg:inline-block">
        {navHeaders.map(item => {
          if (item.type === "href")
            return (
              <Link
                to={item.href}
                key={item.id}
                data-item={item.id}
                className="nav-item p-3 text-base font-medium text-slate-600 transition-colors hover:!text-slate-600 group-hover/nav:text-slate-400"
              >
                {item.title}
              </Link>
            )

          return (
            <div
              key={item.id}
              data-item={item.id}
              className="nav-item relative inline-block"
            >
              <button
                key={item.id}
                data-item={item.id}
                className="inline-flex items-center gap-1 rounded-md bg-white p-3 font-medium text-slate-600 focus:outline-none focus:ring-slate-200"
              >
                {item.title}
                <ChevronDownIcon
                  className="h-4 w-4 fill-slate-400 transition-colors group-hover/menu:fill-slate-600"
                  aria-hidden="true"
                />
              </button>
              <div className="sub-menu absolute" data-item={item.id}>
                {item.element}
              </div>
            </div>
          )
        })}
        <div className="pseudo-menu-container pointer-events-none absolute">
          <div className="clipper"></div>
        </div>
      </nav>
    </div>
  )
}

export default Navigation
