import React from "react"
import SectionHead from "./SectionHead"
import ChevronDown from "@heroicons/react/outline/ChevronDownIcon"
import ChevronUp from "@heroicons/react/outline/ChevronUpIcon"
import puzzle from "../../../static/icons/puzzle.webp"

import AssetCardinality1 from "../../../static/home/asset-cardinality-1.inline.svg"
import AssetCardinality2 from "../../../static/home/asset-cardinality-2.inline.svg"

const HighCardinality = ({
  title = "Purpose built for High Cardinality.",
  icon = puzzle,
  accordions = [
    {
      title: "A high-fidelity observability experience.",
      description:
        "Instrument your system for individual users, tenants, sessions, etc. without worrying about cardinality explosion. Unlock granular monitoring and alerting for use-cases like tenant-level debugging, and use Changeboards for correlated health.",
    },
    {
      title: "Cardinality workflows and superior defaults.",
      description:
        "Along with a starting quota of 20M time series per metric, tools like Cardinality Explorer enable you to understand impacted metrics and use Streaming Aggregation to control it without instrumentation changes.",
    },
    {
      title: "Not just for Metrics, but for Logs and Traces as well.",
      description:
        "Improve your MTTD by not having to drop crucial information from your logs and traces. With Logs to Metrics and Traces to Metrics, switch between telemetry while viewing infrastructure and application monitoring. ",
    },
  ],
}) => {
  const [open, setOpen] = React.useState(0)
  return (
    <section className="mx-auto flex w-full max-w-6xl flex-col gap-16 py-20">
      <SectionHead title={title} icon={icon} />
      <div className="group flex flex-col gap-16 md:flex-row md:items-center">
        <div className="flex w-full max-w-[480px] flex-col gap-10">
          {accordions.map((accordion, index) => (
            <div className="flex flex-col gap-3">
              <button
                onClick={() => setOpen(index)}
                className="flex items-start justify-between gap-4 text-left text-xl font-bold text-slate-600"
              >
                <span>{accordion.title}</span>
                <span className="py-0.5">
                  {open === index ? (
                    <ChevronUp className="h-6 w-6 text-slate-400" />
                  ) : (
                    <ChevronDown className="h-6 w-6 text-slate-400" />
                  )}
                </span>
              </button>
              {open === index && (
                <div className="text-left text-slate-500">
                  {accordion.description}
                </div>
              )}
            </div>
          ))}
        </div>
        <div className="w-full">
          <div className="relative">
            <AssetCardinality1 />
            <div className="absolute inset-0">
              <AssetCardinality2 className="opacity-0 transition-opacity duration-500 group-hover:opacity-100" />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default HighCardinality
