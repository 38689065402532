import React from "react"
import { PropTypes } from "prop-types"
import Post from "./post"
import Subscription from "../blog/subscription"

const Posts = ({ posts, heading }) => {
  return (
    <div>
      <div className="px-4 md:px-6 xl:px-0">
        {/* section header */}
        <div className="flex items-center justify-between">
          <div className="my-7 flex flex-col gap-6 text-3xl font-extrabold lg:my-10 lg:text-5xl">
            Changelog
            <div className="text-base font-normal text-slate-500 lg:text-lg">
              <span className="text-slate-500">
                Updates on Levitate; a managed time series data warehouse built to tame high cardinality.
              </span>{" "}
              ·{" "}
              <a
                href="https://twitter.com/last9io"
                className="text-blue-500 underline-offset-2 hover:underline"
              >
                Follow us on Twitter
              </a>
            </div>
          </div>
        </div>
        {posts !== null && posts.length > 0 && (
          <hr className="mb-8 text-white text-opacity-25" />
        )}
        {/* end section header */}
      </div>

      {/* subscription */}
      <div className="mx-auto mb-20 max-w-6xl px-4 md:px-6 xl:px-0">
        <Subscription subType="changelog" />
      </div>
      {/* end subscription end */}

      {/* Loading  */}
      {posts === null && (
        <span className="my-10 px-4 text-center text-xl md:px-6 lg:text-3xl xl:px-0">
          Loading...
        </span>
      )}

      {posts !== null && posts.length > 0 && (
        <div className="grid grid-flow-row grid-cols-12 gap-x-6 gap-y-12 px-4 md:px-6 xl:gap-x-12 xl:gap-y-24 xl:px-0">
          {posts.map(post => (
            <React.Fragment key={post.id}>
              <Post post={post} />
            </React.Fragment>
          ))}
        </div>
      )}
    </div>
  )
}

Posts.propTypes = {
  posts: PropTypes.array,
  heading: PropTypes.string,
}

Posts.defaultProps = {
  posts: [],
  heading: ``,
}

export default Posts
