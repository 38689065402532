import React from "react"
import Quote from "./Quote"
import imageSrc from "../../../static/home/replit-quote-image.webp"
import ClientLogo from "../common/ClientLogo"

import Hotstar from "../../../static/clients/colored/hotstar.inline.svg"
import Clevertap from "../../../static/clients/colored/clevertap.inline.svg"
import Axio from "../../../static/clients/colored/axio.inline.svg"
import Nxtjob from "../../../static/clients/colored/nxtjob.inline.svg"
import Quickwork from "../../../static/clients/colored/quickwork.inline.svg"
import Circle from "../../../static/clients/colored/circle.inline.svg"
import Probo from "../../../static/clients/colored/probo.inline.svg"
import Tacitbase from "../../../static/clients/colored/tacitbase.inline.svg"
import Replit from "../../../static/clients/colored/replit.inline.svg"
import Dotconn from "../../../static/clients/colored/dotconn.inline.svg"
import clsx from "clsx"

const Clients = () => {
  const clients = [
    <Hotstar className="h-14" />,
    <Clevertap className="h-14" />,
    <Probo className="h-14" />,
    <Axio className="h-14" />,
    <Nxtjob className="h-14" />,
    <Quickwork className="h-14" />,
    <Circle className="h-14" />,
    <Tacitbase className="h-14" />,
    <Replit className="h-14" />,
    <Dotconn className="h-14" />,
  ]
  return (
    <section className="px-6 pb-20 pt-12">
      <div className="mx-auto w-full max-w-6xl">
        <Quote
          color="#EC4E02"
          img={<img src={imageSrc} className="h-auto w-full max-w-72" />}
        />
      </div>
      <div
        className={clsx(
          "relative w-full overflow-x-hidden whitespace-nowrap px-10",
          "before:absolute before:left-0 before:z-10 before:h-full before:w-10 before:bg-gradient-to-r before:from-white before:to-white/0 before:content-['']",
          "after:absolute after:right-0 after:z-10 after:h-full after:w-10 after:bg-gradient-to-l after:from-white after:to-white/0 after:content-['']",
        )}
      >
        <div className="logocarousel inline-block">
          <div className="flex gap-20 px-10">
            {clients.map(client => {
              return client
            })}
            {clients.map(client => {
              return client
            })}
          </div>
        </div>
      </div>
    </section>
  )
}

export default Clients
