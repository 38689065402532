import React from "react"
import Seo from "../components/common/seo"
import Layout from "../components/common/layout"
import LevitatePreFooter from "../components/levitate/LevitatePreFooter"
import MonitoringScale from "../components/opentelemetry/MonitoringScale"
import UnifiedDataWarehouse from "../components/opentelemetry/UnifiedDataWarehouse"
import Hero from "../components/opentelemetry/Hero"
import Why from "../components/opentelemetry/Why"
import ClientLogosQueue from "../components/common/ClientLogosQueue"

export default function LandingPage() {
  return (
    <Layout>
      {/** Hero section */}
      <section className="flex flex-col px-4 md:px-6 md:pt-14 lg:pt-20 xl:px-0">
        <Hero />
      </section>

      {/** Content section*/}
      <section className="flex flex-col items-center gap-28 bg-gradient-to-b from-yellow-50 to-white px-4 pb-28 md:gap-40 md:px-6 md:pb-40 lg:mx-0 xl:px-0">
        <div className="lg:h-0 w-full">
          <ClientLogosQueue />
        </div>

        <Why />
        <UnifiedDataWarehouse />
        <MonitoringScale />
      </section>

      <LevitatePreFooter />
    </Layout>
  )
}

export const Head = () => (
  <Seo
    title="Levitate — Open Standards"
    description="Integrate with ease with OpenTelemetry, OpenMetrics, and many more open standards with Levitate"
    image="/thumbnails/thumbnail-last9-levitate.jpg"
  />
)
