/* eslint-disable react/prop-types */
import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/common/layout"
import Seo from "../components/common/seo"
import ReadingProgress from "../components/common/ReadingProgress"

import GhostPostProvider from "../contexts/GhostPostContext"
import Header from "../components/changelog/Header"
import HeroImage from "../components/blog/HeroImage"
import Content from "../components/changelog/Content"

const ChangelogDetails = ({ data }) => {
  const target = React.createRef()
  return (
    <GhostPostProvider post={data.ghostPost}>
      <Layout>
        <div className="px-4 lg:px-6">
          <ReadingProgress target={target} />
          {/* blog post */}
          <div
            ref={target}
            className="mx-auto grid w-full max-w-6xl grid-cols-12 gap-y-10 pb-16 pt-14 lg:gap-y-16 lg:pb-32 lg:pt-20"
          >
            <Header minimal />
            <HeroImage />
            <Content />
          </div>
        </div>
      </Layout>
    </GhostPostProvider>
  )
}

export default ChangelogDetails

export const query = graphql`
  query ($slug: String!, $primaryTagSlug: String!) {
    ghostPost(slug: { eq: $slug }) {
      id
      title
      slug
      html
      excerpt
      reading_time
      feature_image
      published_at
      primary_author {
        id
        bio
        name
        profile_image
        slug
        twitter
      }
      primary_tag {
        id
        name
        slug
        url
        visibility
      }
      authors {
        id
        bio
        name
        profile_image
        slug
        twitter
        url
      }
      tags {
        id
        name
        slug
        url
        visibility
      }
    }
    relatedPosts: allGhostPost(
      filter: {
        primary_tag: { slug: { eq: $primaryTagSlug } }
        slug: { ne: $slug }
      }
      limit: 3
    ) {
      edges {
        node {
          authors {
            name
            profile_image
          }
          slug
          title
          excerpt
          feature_image
        }
      }
    }
  }
`

export const Head = ({
  data: {
    ghostPost: { title, excerpt, feature_image: featureImage },
  },
}) => <Seo title={title} description={excerpt} image={featureImage} />
