import React from "react"
import Layout from "../../components/common/layout"
import Seo from "../../components/common/seo"
import Hero from "../../components/customer-stories/index/hero"
import ClientLogo from "../../components/common/ClientLogo"
import Quote from "../../components/customer-stories/index/quote"
import Card from "../../components/customer-stories/index/card"
import streamingThumbnail from "../../../static/customer-stories/thumbnail-customer-story-live-streaming.jpg"
import quickworkThumbnail from "../../../static/customer-stories/thumbnail-customer-story-quickwork.jpg"
import proboThumbnail from "../../../static/customer-stories/thumbnail-customer-story-probo.jpg"
import LevitatePreFooter from "../../components/levitate/LevitatePreFooter"

const CustomerStories = () => {
  return (
    <Layout>
      <Hero />
      <div className="mx-auto w-full max-w-6xl bg-white py-40">
        <div className="-mt-80 flex flex-col gap-20 px-4 md:gap-40 md:px-6 xl:px-0">
          <div>
            <div className="grid grid-cols-1 justify-items-center gap-10 md:grid-cols-2">
              <Card
                bg={proboThumbnail}
                title="Probo Cuts Monitoring Costs by 90% with Last9"
                path="/customer-stories/probo-cuts-monitoring-costs-by-90-per-cent-with-last9/"
              />
              <Card
                bg={quickworkThumbnail}
                title="Quickwork champions platform transparency for its customers with Last9"
                path="/customer-stories/quickwork-champions-platform-transparency-for-its-customers-with-last9/"
              />
              <Card
                bg={streamingThumbnail}
                title="Reliable Observability for 25+ million concurrent live-streaming viewers"
                path="/customer-stories/reliable-observability-for-25-million-concurrent-live-streaming-viewers/"
              />
              {/* <Card /> */}
            </div>
          </div>
          <div>
            <Quote />
          </div>
          <div className="mx-auto flex max-w-3xl flex-col items-center gap-4">
            <p className="text-sm font-bold uppercase leading-10 text-slate-400">
              Trusted by engineering teams at scale
            </p>
            <div className="grid grid-cols-2 place-content-center place-items-center gap-10 md:flex md:flex-wrap">
              <ClientLogo name="replit" size={56} color="#94A3B8" />
              <ClientLogo name="clevertap" size={56} color="#94A3B8" />
              <ClientLogo name="axio" size={56} color="#94A3B8" />
              <ClientLogo name="probo" size={56} color="#94A3B8" />
              <ClientLogo name="quickwork" size={56} color="#94A3B8" />
              <ClientLogo name="circle" size={56} color="#94A3B8" />
              <ClientLogo name="nxtjob" size={56} color="#94A3B8" />
              <ClientLogo name="tacitbase" size={56} color="#94A3B8" />
              <ClientLogo name="dotconn" size={56} color="#94A3B8" />
            </div>
          </div>
        </div>
      </div>
      <LevitatePreFooter />
    </Layout>
  )
}

export const Head = () => (
  <Seo
    title="Customer Stories"
    description="From our customers - stories of how organizations are achieving their Observability goals with Last9"
  />
)

export default CustomerStories
