import React from "react"
import Balancer from "react-wrap-balancer"
import healthBoardVideo from "../../../static/alert-studio/alert-studio-product-video.mp4"

const HealthBoard = () => {
  return (
    <div className="mx-auto flex w-full max-w-6xl flex-col items-stretch gap-8 md:flex-row-reverse lg:gap-16">
      <div className="flex w-full flex-col overflow-hidden rounded-3xl md:w-auto md:basis-1/2">
        <video width="auto" height="auto" autoPlay loop muted>
          <source src={healthBoardVideo} type="video/mp4" />
        </video>
      </div>

      {/** Right partition */}
      <div className="flex flex-col gap-6 text-slate-600 md:basis-1/2 md:justify-center">
        <h3 className="w-full text-center text-4xl font-black tracking-tight text-black md:max-w-md md:text-left md:text-6xl">
          <Balancer>Anomalous Pattern Detection</Balancer>
        </h3>
        <div className="flex flex-col gap-4 text-base md:text-xl">
          {/*
          <p>
            Visualize alerts in a <b>Health</b> board. Fix recurring issues by
            spotting patterns using historical trends. Drill down on an alert
            when needed, identify the root cause of an Alert by using
            backtesting and <b>Explain Alert </b> playground.{" "}
            </p>
               */}
          <p>
            Don't get limited by just static thresholds for setting alert rules.
          </p>
          <p>
            Detect spike changes, loss of signal, baseline deviation and noisy
            neighbors using  
            <a
              className="underline"
              href="https://docs.last9.io/docs/anomalous-pattern-detection-guide"
            >
              Anomalous Pattern Detection
            </a>
            .
          </p>
        </div>
      </div>
      {/** Right partition ends */}
    </div>
  )
}

export default HealthBoard
